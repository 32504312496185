<script>
import { maska } from 'maska'
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs, computed } from 'vue'
import { internalZipLookup } from '@/util/validations'

const stepName = 'Zip'

export default {
  name: stepName,

  directives: {
    maska
  },

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)

      const searchParams = new URLSearchParams(location.search)

      if (searchParams.get('zip_code') && searchParams.get('slb') === '1') {
        emit('next-step', 'LeaveBehindAds')
      }
    })

    const state = reactive({
      zip: {
        value: '',
        error: false
      }
    })

    const getZipData = async () => {
      //this line of code is to ensure that if the correct zip is entered and the user keeps trying to type, an error will not display
      if (state.zip.value.length > 5) return

      //check if zip length is good
      if (state.zip.value.length < 5) {
        state.zip.error = true
        return
      }

      //if zip length is okay, we can use internal lookup to validate zip further
      const zipData = await internalZipLookup(state.zip.value)
      state.zip.error = zipData.zipError

      if (state.zip.error) return

      return zipData
    }

    const proceedNextStep = async (event) => {
      // if (state.zip.error) {
      //   //prevent default opening tab if zip has error
      //   event.preventDefault()
      //   return
      // }

      emit('next-step', stepName)
    }

    const validateZip = async () => {
      const zipInfo = await getZipData(state.zip.value)
      if (!zipInfo || zipInfo.zipError) return

      const { county, city, stateCode } = zipInfo
      const answers = [
        {field: 'county', value: county},
        {field: 'city', value: city},
        {field: 'stateCode', value: stateCode},
        {field: 'zip_code', value: state.zip.value}
      ]

      store.commit('SET_FIELDS', answers)

      // //store in local storage
      // const localStorage = window.localStorage
      // await localStorage.setItem('zip_data', JSON.stringify(answers))
      await proceedNextStep(null)
    }

    const leaveBehindUrl = computed(() => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('zip_code', state.zip.value)
      searchParams.set('slb', '1')

      if (store.state.fields['ef_transaction_id']) {
        searchParams.set('ef_transaction_id', store.state.fields['ef_transaction_id'])
      }

      if (state.zip.error) return 'javascript:;'
      return location.pathname + '?' + searchParams.toString()
    })

    return {
      proceedNextStep,
      ...toRefs(state),
      leaveBehindUrl,
      validateZip
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <input
          class="form-input zip-input"
          :class="{'zip-error' : zip.error}"
          type="text"
          placeholder="Enter Zip"
          v-maska="'#####'"
          v-model="zip.value"
        />
        <div class="error-container">
          <p class="error-text" v-if="zip.error">Please enter a valid zip code to proceed.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <a @click="validateZip">
          <button
            class="main-button main-button-w"
          >
            Continue
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/zip.scss';
</style>